<template>
	<v-row no-gutters dense class="delivery-home">
		<v-col cols="12" class="pa-0">
			<v-row no-gutters>
				<v-col cols="6">
					<p class="my-heading ma-0">Deliveries</p>
				</v-col>
				<v-col cols="6" class="header-link d-flex justify-end align-center pb-sm-5 pb-0">
					<span class="d-sm-block d-none" v-bind:class="{ active: filter == 'all' }" v-on:click="filter = 'all'">All</span>
					<span class="d-sm-block d-none" v-bind:class="{ active: filter == 'to_deliver' }" v-on:click="filter = 'to_deliver'">To Deliver</span>
					<span class="d-sm-block d-none" v-bind:class="{ active: filter == 'delivered' }" v-on:click="filter = 'delivered'">Delivered</span>
					<span class="d-sm-block d-none" v-bind:class="{ active: filter == 'rejected' }" v-on:click="filter = 'rejected'">Rejected</span>
					<span v-on:click="getDeliveryReports()">
						<v-tooltip left>
							<template v-slot:activator="{ on }">
								<img
									v-on="on"
									:src="$store.state.icons.icons['file-export']"
									style="height: 20px; filter: invert(61%) sepia(10%) saturate(1008%) hue-rotate(178deg) brightness(84%) contrast(84%)"
									alt
								/>
							</template>
							<span>Export delivery list</span>
						</v-tooltip>
					</span>
				</v-col>
			</v-row>

			<div v-if="no_data === true">
				<div class="d-flex align-center justify-center" style="height: 70vh">
					<p class="no-data">No deliveries available</p>
				</div>
			</div>

			<div v-if="deliveries.length > 0">
				<div v-if="filter === 'all'">
					<transition-group name="slide-in" :style="{ '--total': deliveries.length }">
						<v-row
							v-for="(delivery, index) in deliveries"
							v-bind:key="'delivery' + index"
							no-gutters
							:style="{ '--i': index }"
							class="d-flex flex-column add-remove-item"
						>
							<div v-if="delivery.date === 'overdue' && delivery.deliveries.length > 0">
								<p class="delivery-sf20 pb-5 pt-8 ma-0">
									Overdue
									<span class="delivery-mf15">• {{ delivery.deliveries.length }} deliveries</span>
								</p>
								<app-delivery-list-view :value="delivery.deliveries"></app-delivery-list-view>
							</div>

							<div v-else-if="delivery.date === 'today' && delivery.deliveries.length > 0">
								<p class="delivery-sf20 pb-5 pt-8 ma-0">
									Today
									<span class="delivery-mf15"
										>•
										{{ delivery.deliveries.length }}
										deliveries</span
									>
								</p>
								<app-delivery-list-view :value="delivery.deliveries"></app-delivery-list-view>
							</div>
							<div v-else-if="delivery.date === 'tomorrow' && delivery.deliveries.length > 0">
								<p class="delivery-sf20 pb-5 pt-8 ma-0">
									Tomorrow
									<span class="delivery-mf15"
										>•
										{{ delivery.deliveries.length }}
										deliveries</span
									>
								</p>
								<app-delivery-list-view :value="delivery.deliveries"></app-delivery-list-view>
							</div>
							<div v-else-if="delivery.date > tomorrow && filterDelivery(delivery.deliveries).length > 0">
								<p class="delivery-sf20 pb-5 pt-8 ma-0">
									{{ delivery.date }}
									<span class="delivery-mf15"
										>•
										{{ filterDelivery(delivery.deliveries).length }}
										deliveries</span
									>
								</p>
								<app-delivery-list-view :value="filterDelivery(delivery.deliveries)"></app-delivery-list-view>
							</div>
						</v-row>
					</transition-group>
				</div>
				<div v-else>
					<div v-if="filter === 'to_deliver'">
						<p class="delivery-sf20 pb-5 pt-8 ma-0">To Deliver</p>
					</div>

					<div v-else-if="filter === 'delivered'">
						<p class="delivery-sf20 pb-5 pt-8 ma-0">Delivered</p>
					</div>

					<div v-else-if="filter === 'rejected'">
						<p class="delivery-sf20 pb-5 pt-8 ma-0">Rejected by contractor</p>
					</div>

					<app-delivery-list-view :value="deliveries"></app-delivery-list-view>
				</div>
			</div>
		</v-col>

		<div style="width: 100%">
			<v-menu top auto offset-x min-width="200px" :close-on-click="closeOnClick">
				<template v-slot:activator="{ on, attrs }">
					<div icon class="d-sm-none text-end filter-icon mb-5" style="width: auto">
						<i v-if="!filter_exit" @click="filter_exit = !filter_exit" class="mdi mdi-filter-outline icon-circle" v-bind="attrs" v-on="on" />
						<i v-else class="mdi mdi-close icon-circle" @click="filter_exit = !filter_exit" v-bind="attrs" v-on="on" />
					</div>
				</template>

				<v-list width="200px">
					<v-list-item>
						<v-list-item-title>
							<span v-bind:class="{ active: filter == 'all' }" v-on:click=";(filter = 'all'), (filter_exit = !filter_exit)">All</span>
						</v-list-item-title>
					</v-list-item>
					<v-list-item>
						<v-list-item-title>
							<span v-bind:class="{ active: filter == 'to_deliver' }" v-on:click=";(filter = 'to_deliver'), (filter_exit = !filter_exit)"
								>To Deliver</span
							>
						</v-list-item-title>
					</v-list-item>
					<v-list-item>
						<v-list-item-title>
							<span v-bind:class="{ active: filter == 'delivered' }" v-on:click=";(filter = 'delivered'), (filter_exit = !filter_exit)"
								>Delivered</span
							>
						</v-list-item-title>
					</v-list-item>
					<v-list-item>
						<v-list-item-title>
							<span v-bind:class="{ active: filter == 'rejected' }" v-on:click=";(filter = 'rejected'), (filter_exit = !filter_exit)"
								>Rejected</span
							>
						</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</div>
		<app-bid-delivery ref="bidDeliveryDetail" v-if="bidDeliveryDetail"></app-bid-delivery>
	</v-row>
</template>

<script>
import moment from 'moment'
import { ExportToCsv } from 'export-to-csv'
import { deliveries, recentDeliveries } from '../../services/deliveryservice'
import { errorLogs } from '../../services/authservices'
import { covLocalTime, covLocalDate, timeFormat } from '../../services/commonservice'
const DeliveryListView = () => import('../../mycomponents/DeliveryList')
const BidDelivery = () => import('../bidcomponent/BidDelivery')

export default {
	components: {
		AppBidDelivery: BidDelivery,
		AppDeliveryListView: DeliveryListView,
	},
	data() {
		return {
			filter: 'all',
			no_data: false,
			my_timer: 0,
			map_lat_long: {},
			new_list: true,
			delivery_data: [],
			filter_exit: false,
			closeOnClick: false,
			bidDeliveryDetail: false,
		}
	},

	created() {
		this.$store.watch(
			(state) => {
				return state.list
			},
			() => {
				this.updateList()
			},
			{ deep: true }
		)

		if (this.$store.state.auth.not_supported) {
			this.autoUpdate()
		}
	},

	beforeDestroy() {
		clearInterval(this.my_timer)
	},

	async mounted() {
		await this.getDeliveries()
		if (this.$route.query.data) {
			this.bidDeliveryDetail = true
			let queryData = JSON.parse(this.$route.query.data)
			let bidInterval = setInterval(() => {
				if (this.delivery_data.length > 0) {
					this.$refs.bidDeliveryDetail.deliverySubmit(queryData.id)
					clearInterval(bidInterval)
				}
			}, 500)
		}
	},

	watch: {
		async $route(to) {
			if (to.query.data) {
				let queryData = JSON.parse(to.query.data)
				setTimeout(() => {
					if (this.delivery_data.length > 0) {
						this.$refs.bidDeliveryDetail.deliverySubmit(queryData.id)
					}
				})
			} else {
				this.$refs.bidDeliveryDetail.closeBidBox()
			}
		},
		deep: true,
		immediate: true,
	},

	computed: {
		deliveries() {
			if (this.delivery_data.length > 0) {
				let delivery_ob
				if (this.filter === 'all') {
					delivery_ob = this.groupBy(this.delivery_data)
				} else if (this.filter === 'to_deliver') {
					delivery_ob = this.delivery_data.filter((delivery) => {
						return delivery.status === 'DP'
					})
				} else if (this.filter === 'delivered') {
					delivery_ob = this.delivery_data.filter((delivery) => {
						return delivery.status === 'D'
					})
				} else if (this.filter === 'rejected') {
					delivery_ob = this.delivery_data.filter((delivery) => {
						return delivery.status === 'R'
					})
				}

				this.no_data = delivery_ob.length > 0 ? false : true
				return delivery_ob
			} else {
				return this.delivery_data
			}
		},

		today() {
			return moment().tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss')
		},

		tomorrow() {
			return moment().add('1', 'days').tz(moment.tz.guess()).format('YYYY-MM-DD')
		},
	},

	methods: {
		sortedArray() {
			function compare(a, b) {
				if (a.date < b.date) return -1
				if (a.date > b.date) return 1
				return 0
			}

			return this.delivery_data.sort(compare)
		},

		groupBy(array) {
			let result = []
			let overdue = []
			let today = []
			let tomorrow = []
			let tomorrowDate = moment().add('1', 'days').tz(moment.tz.guess()).format('YYYY-MM-DD')
			array.forEach((item) => {
				if (item.delivery_datetime < this.today && (item.status === 'IP' || item.status === 'DP')) {
					overdue.push(item)
				} else if (item.delivery_datetime >= this.today && moment(item.delivery_datetime).format('YYYY-MM-DD') < tomorrowDate) {
					today.push(item)
				} else if (moment(item.delivery_datetime).format('YYYY-MM-DD') === tomorrowDate) {
					tomorrow.push(item)
				} else if (moment(item.delivery_datetime).format('YYYY-MM-DD') > tomorrowDate) {
					let new_ob = true
					let key = item.date
					result.forEach((el) => {
						if (el.date === key) {
							new_ob = false
							el.deliveries.push(item)
						}
					})

					if (new_ob === true) {
						result.push({
							date: key,
							deliveries: [item],
						})
					}
				}
			})

			if (tomorrow.length > 0) {
				result.splice(0, 0, { date: 'tomorrow', deliveries: tomorrow })
			}

			if (today.length > 0) {
				result.splice(0, 0, { date: 'today', deliveries: today })
			}

			if (overdue.length > 0) {
				result.splice(0, 0, { date: 'overdue', deliveries: overdue })
			}

			return result
		},

		filterDelivery(data) {
			if (this.filter === 'all') {
				let ob = data.filter((delivery) => {
					if (delivery.delivery_datetime < this.today) {
						return delivery.status === 'IP' || delivery.status === 'DP'
					} else {
						return delivery.status
					}
				})
				this.no_data = ob.length > 0 ? false : true
				return ob
			}
		},

		updateList() {
			errorLogs('update list funcation call in delivery page')
			if (this.$store.state.list.delivered.list_id != '') {
				let data = this.$store.state.list.delivered
				this.delivery_data.forEach((item) => {
					if (item.list_id == data.list_id) {
						item.status = data.status
					}
				})
			}

			if (this.$store.state.list.list_data.bid.bid_id != '') {
				if (this.$store.state.list.list_data.bid.status === 'A') {
					errorLogs('Quote accepted')
					this.getDeliveries()
				}
			}

			this.filter = ''
			this.filter = 'all'
		},

		bidDelivery(bid_id) {
			this.$refs.deliverybid.deliverySubmit(bid_id)
		},

		async getDeliveries() {
			let data = await deliveries()
			this.delivery_data = []
			let dataset = data.dataset
			if (dataset && dataset.length > 0) {
				dataset.forEach((item) => {
					let delivery = {}

					delivery.delivery_id = item.delivery_id
					delivery.bid_id = item.bid.bid_id
					delivery.list_id = item.clist.list_id
					delivery.list_name = item.clist.list_name
					delivery.list_address = item.clist.address
					delivery.category_id = item.clist.category_id
					delivery.contact_name = item.contractor.fullname
					delivery.contact_phone = item.contractor.phone
					delivery.status = item.status
					delivery.list_lat = item.clist.latitude
					delivery.list_long = item.clist.longitude
					delivery.bid_lat = item.bid.latitude
					delivery.bid_long = item.bid.longitude
					delivery.check = item.status != 'IP'
					delivery.name = 'delivery' + item.delivery_id
					delivery.date = covLocalDate(item.bid.date_of_delivery, item.bid.start_time)
					delivery.start_time = covLocalTime(delivery.date, item.bid.start_time)
					delivery.end_time = covLocalTime(delivery.date, item.bid.end_time)
					delivery.delivery_datetime = moment(delivery.date + ' ' + delivery.end_time).format('YYYY-MM-DD HH:mm:ss')
					delivery.pickup = item.clist.pickup
					delivery.url = item.clist.url

					this.delivery_data.push(delivery)
				})

				this.delivery_data = this.sortedArray()
			} else {
				this.no_data = true
			}
		},

		getDeliveryReports() {
			const options = {
				filename: 'delivery-report-' + moment().tz(moment.tz.guess()).format('M-D-Y'),
				fieldSeparator: ',',
				quoteStrings: '"',
				decimalSeparator: '.',
				showLabels: true,
				showTitle: true,
				title: 'Delivery status Report',
				useTextFile: false,
				useBom: true,
				useKeysAsHeaders: true,
				// headers: [
				// 	'`Delivery Id`',
				// 	'`Bid Id`',
				// 	'List Id',
				// 	'List Name',
				// 	'Category Id',
				// 	'Contact Name',
				// 	'Contact Phone',
				// 	'Delivery Date',
				// 	'Delivery Time',
				// 	'Status',
				// 	'Pickup',
				// ],
			}

			const csvExporter = new ExportToCsv(options)

			let delivery_ob = []
			this.delivery_data.forEach((item) => {
				let delivery = {}
				delivery.delivery_id = item.delivery_id
				delivery.bid_id = item.bid_id
				delivery.list_id = item.list_id
				delivery.list_name = item.list_name
				delivery.category_id = item.category_id
				delivery.contact_name = item.contact_name
				delivery.contact_phone = item.contact_phone
				delivery.delivery_date = item.date
				delivery.delivery_time = timeFormat(item.start_time) + ' - ' + timeFormat(item.end_time)
				if (item.status === 'IP') {
					delivery.status = 'In Process'
				} else if (item.status === 'DP') {
					delivery.status = 'Dispatch Delivery'
				} else if (item.status === 'R') {
					delivery.status = 'Rejected'
				} else if (item.status === 'D') {
					delivery.status = 'Delivered'
				} else {
					delivery.status = ''
				}
				delivery.pickup = item.pickup

				delivery_ob.push(delivery)
			})
			csvExporter
			csvExporter.generateCsv(delivery_ob)
		},

		async autoUpdate() {
			this.my_timer = setInterval(() => {
				// Deliveries
				this.recentDeliveries()
			}, 30000)
		},

		async recentDeliveries() {
			let data = await recentDeliveries()
			let dataset = data.dataset
			if (dataset && dataset.length > 0) {
				dataset.forEach((item) => {
					let delivery = {}

					delivery.delivery_id = item.delivery_id
					delivery.bid_id = item.bid.bid_id
					delivery.list_id = item.clist.list_id
					delivery.list_name = item.clist.list_name
					delivery.list_address = item.clist.address
					delivery.category_id = item.clist.category_id
					delivery.contact_name = item.contractor.fullname
					delivery.contact_phone = item.contractor.phone
					delivery.status = item.status
					delivery.list_lat = item.clist.latitude
					delivery.list_long = item.clist.longitude
					delivery.bid_lat = item.bid.latitude
					delivery.bid_long = item.bid.longitude
					delivery.check = item.status != 'IP'
					delivery.name = 'delivery' + item.delivery_id
					delivery.date = covLocalDate(item.bid.date_of_delivery, item.bid.start_time)
					delivery.start_time = covLocalTime(delivery.date, item.bid.start_time)
					delivery.end_time = covLocalTime(delivery.date, item.bid.end_time)
					delivery.delivery_datetime = moment(delivery.date + ' ' + delivery.end_time).format('YYYY-MM-DD HH:mm:ss')
					delivery.pickup = item.clist.pickup
					delivery.url = item.clist.url

					//   this.delivery_data.push(delivery);

					let delivery_index = this.delivery_data.findIndex((delivery) => delivery.delivery_id == delivery.delivery_id)

					if (delivery_index >= 0) {
						this.delivery_data[delivery_index] = delivery
					} else {
						this.delivery_data.splice(0, 0, delivery)
					}

					this.delivery_data = this.sortedArray()
					this.filter = ''
					this.filter = 'all'
				})
			}
		},
	},
}
</script>

<style lang="scss">
.delivery-home {
	min-height: 100vh;
	margin-top: -80px;
	height: 100%;
	padding: 112px 32px 32px 32px;
}
</style>
